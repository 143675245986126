import { Alert } from '@helix-design-system/helix-react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Footer from './footer.js';
import Header from './header.js';
import Sidebar from './sidebar.js';
import { OFFICE365_CONTROL_PANEL } from '../links.js';
import {
  loadAssignedLicenses,
  readUserProfile,
  authenticateUser,
} from '../store/actions';
import {
  getAccount,
  getAccountStatus,
  getLicenseDetails,
  getLoginStatus,
  getLogoutStatus,
  getProfile,
  getProfileStatus,
} from '../store/selectors';
import { trackCustomEvent } from '../utils/googleAnalytics.js';
import { ERROR, PENDING } from '../utils/statusTypes.js';

const isBrowser = typeof window !== `undefined`;

const Layout = ({ children, enableLogin }) => {
  const dispatch = useDispatch();

  const loginStatus = useSelector(getLoginStatus);
  const isLoginErrored = loginStatus === ERROR;

  const account = useSelector(getAccount);
  const homeAccountId = account?.homeAccountId;
  const username = account?.username;
  const accountStatus = useSelector(getAccountStatus);
  const isAccountStatusLoading = accountStatus === PENDING;

  const profile = useSelector(getProfile);
  const displayName = profile?.displayName;
  const profileStatus = useSelector(getProfileStatus);
  const isUserDetailsLoading = profileStatus === PENDING;
  const name = displayName || username;

  const logoutStatus = useSelector(getLogoutStatus);
  const isLogoutLoading = logoutStatus === PENDING;

  const isLoggedIn = isBrowser ? window.location.search.substr(1) === 'login' : null;
  const isSidebarLoading = isAccountStatusLoading || isUserDetailsLoading || isLogoutLoading;
  const showSidebar = isAccountStatusLoading || (isLoggedIn && account !== null) || isLogoutLoading;

  const assignedLicenses = useSelector(getLicenseDetails);

  const handlePageRefresh = () => {
    if (isBrowser) {
      window.location.reload();
      return;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (isLoggedIn) {
        const accessToken = await dispatch(authenticateUser());

        if (!!accessToken) {
          dispatch(readUserProfile({ accessToken }));
          dispatch(loadAssignedLicenses({ accessToken }));
        }
      }
    };

    fetchUserData();
  }, [dispatch, isLoggedIn]);

  return (
    <>
      <Header
        enableLogin={enableLogin}
        homeAccountId={homeAccountId}
        hasAccount={!!account}
        isLogoutLoading={isLogoutLoading}
        loginStatus={loginStatus}
        username={username}
      />
      <div id="app">
        <div id="stage">
          <nav id="nav" className="hxNav w-56 bg-gray-50 text-gray-950">
            <a
              href={OFFICE365_CONTROL_PANEL}
              onClick={() =>
                trackCustomEvent({
                  action: 'Click',
                  category: 'Support',
                  label: 'Manage 365 Rackspace Admin Portal',
                })
              }
            >
              Manage 365 Rackspace Admin Portal
            </a>
          </nav>
          <div>
            {isLoginErrored && (
              <Alert
                cta={'Reload page'}
                onSubmit={handlePageRefresh}
                persist={true}
                type="error"
              >
                Whoops! We weren't able to log you in. Please try again later.
              </Alert>
            )}
            <div className="flex h-full">
              <main role="main" id="content" className="hxBox hxMd mt-4">
                {children}
              </main>
              {showSidebar && (
                <Sidebar
                  isLoading={isSidebarLoading}
                  isShown={showSidebar}
                  assignedLicenses={assignedLicenses}
                  name={name}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  enableLogin: PropTypes.bool,
};

export default Layout;
