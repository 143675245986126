const getLink = (permalink) => `/support/how-to/${permalink}`;
const getCDNLink = (permalink) => `https://media.manage365.rackspace.com/${permalink}`;

export const APPLE_MAIL = getLink(
  'set-up-your-microsoft-365-exchange-online-mailbox-in-apple-mail-for-macos'
);
export const APPLE_MAIL_IOS = getLink(
  'set-up-microsoft-365-exchange-online-mailbox-ios'
);
export const APPLE_MAIL_IPAD = getLink(
  'set-up-microsoft-365-exchange-online-mailbox-in-ipad-mail'
);
export const EXCHANGE_GENERAL = getLink('set-up-exchange-online-email');
export const HELP_ONEDRIVE = getLink(
  'troubleshoot-onedrive-with-the-microsoft-support-and-recovery-assistant'
);
export const HELP_OUTLOOK = getLink(
  'troubleshoot-outlook-with-the-microsoft-support-and-recovery-assistant'
);
export const OFFICE365_APPS = 'https://portal.office.com';
export const OFFICE365_CONTROL_PANEL = 'https://office365.cp.rackspace.com/';
export const ONEDRIVE_ANDROID = getLink(
  'set-up-onedrive-for-android-mobile-devices'
);
export const ONEDRIVE_ANDROID_TAB = getLink(
  'set-up-onedrive-for-business-for-android-tablets'
);
export const ONEDRIVE_IOS = getLink('set-up-onedrive-for-business-for-ios');
export const ONEDRIVE_IPAD = getLink('set-up-onedrive-for-business-for-ipad');
export const ONEDRIVE_MAC = getLink('set-up-onedrive-for-business-for-macos');
export const ONEDRIVE_WINDOWS = getLink(
  'set-up-onedrive-for-business-for-windows'
);
export const OUTLOOK_ANDROID = getLink(
  'set-up-your-office-365-mailbox-in-the-outlook-for-android-mobile-application'
);
export const OUTLOOK_ANDROID_TAB = getLink(
  'set-up-your-microsft-365-exchange-online-mailbox-in-outlook-for-android-tablets'
);
export const OUTLOOK_EMAIL = 'https://outlook.office.com'
export const OUTLOOK_IOS = getLink(
  'set-up-your-microsoft-365-exchange-online-mailbox-in-outlook-for-ios'
);
export const OUTLOOK_IPAD = getLink(
  'set-up-your-microsoft-365-exchange-online-mailbox-in-outlook-for-ipad-os'
);
export const OUTLOOK_MAC = getLink('set-up-mac-outlook-setup-o365');
export const OUTLOOK_WINDOWS = getLink(
  'set-up-your-microsoft-365-exchange-online-mailbox-in-outlook-for-windows'
);
export const RACKSPACE_SUPPORT = 'https://support.rackspace.com';
export const SARA =
  'https://support.microsoft.com/en-us/office/about-the-microsoft-support-and-recovery-assistant-e90bb691-c2a7-4697-a94f-88836856c72f';
export const ARCHIVING_VIDEO = getCDNLink('videos/EmailArchiving.mp4');
export const ARCHIVING_DATA_SHEET = getCDNLink('documents/M365RackspaceEmailArchiving.pdf');
