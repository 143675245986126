import requestHelper, { defaultMethod, defaultExpectedStatues } from './requestHelper.js';

class ApiClient {
  constructor(request = requestHelper) {
    this._request = request;
    this._raiseError = () => {};
  }

  initialize = (raiseError) => {
    this._raiseError = raiseError;
  };

  request = async (uri, requestInfo, options = {}) => {
    let resp = await this._request(uri, requestInfo);
    if (!resp.success) {
      this._handleError(uri, requestInfo, resp, options.handleError);
    }
    return resp;
  };

  _handleError(uri, requestInfo, resp, errorHandler) {
    const raiseError = errorHandler || this._raiseError;
    const { method, expectedStatuses } = requestInfo || {};
    if (resp.fetchError) {
      raiseError('Failed to contact the server', resp.fetchError);
    } else if (resp.parseError) {
      raiseError('Server returned unexpected data', resp.parseError, {
        status: resp.status,
        statusText: resp.statusText,
      });
    } else {
      const { data = {}, status, statusText } = resp;
      if (status === 0 || status >= 500) {
        raiseError(statusText || 'NO RESPONSE');
      } else {
        const defaultMessage = 'Server returned an unexpected response';
        const exception = data.message || null;
        const message = status === 403 ? 'You do not have permission to access this resource' : defaultMessage;
        const details = {
          method: method || defaultMethod,
          status,
          statusText,
          expectedStatuses: expectedStatuses || defaultExpectedStatues,
          uri: uri ? uri.toString() : '',
        };
        raiseError(message, exception, details);
      }
    }
  }
}

let { initialize, request } = new ApiClient();

export { request as default, initialize as initializeRequest, ApiClient };
