import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import HeaderDropdown from './HeaderDropdown.js';
import Logo from '../images/rackspace-logo.svg';
import { logInUser } from '../store/actions';
import { PENDING } from '../utils/statusTypes.js';

const Header = ({
  enableLogin,
  homeAccountId,
  hasAccount,
  isLogoutLoading,
  loginStatus,
  username,
}) => {
  const dispatch = useDispatch();

  const isLoading = loginStatus === PENDING || isLogoutLoading;
  const handleLogin = () => {
    dispatch(logInUser());
  };

  return (
    <header>
      <nav id="hxTopNav" className="h-full w-full flex flex-col">
        <div className="hxNavGradient"></div>
        <div className="flex flex-row justify-between items-center w-full">
          <Link className="hxTopNavLogo py-0 flex flex-col items-center justify-center" to="/">
            <Logo className="h-8 w-auto text-gray-1000" />
          </Link>
          {enableLogin &&
            (hasAccount ? (
              <HeaderDropdown homeAccountId={homeAccountId} username={username} />
            ) : (
              <button
                className="hxBtn hxPrimary mr-5"
                disabled={isLoading}
                onClick={handleLogin}
              >
                Microsoft Account Login
                {isLoading && <hx-busy class="ml-2"></hx-busy>}
              </button>
            ))}
        </div>
      </nav>
    </header>
  );
};

Header.propTypes = {
  enableLogin: PropTypes.bool,
  homeAccountId: PropTypes.string,
  hasAccount: PropTypes.bool.isRequired,
  isLogoutLoading: PropTypes.bool.isRequired,
  loginStatus: PropTypes.string,
  username: PropTypes.string,
};

Header.defaultProps = {
  enableLogin: true,
}

export default Header;
