import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { logOutUser } from '../store/actions';

const HeaderDropdown = ({ homeAccountId, username }) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logOutUser({ homeAccountId }));
  };

  return (
    <div className="hxTopNavMenu">
      <hx-disclosure aria-controls="user-menu">
        <hx-icon class="hxNavUser" type="user"></hx-icon>
        <span>{username}</span>
        <hx-icon class="hxPrimary" type="angle-down"></hx-icon>
      </hx-disclosure>
      <hx-menu id="user-menu" position="bottom-end">
        <section className="pt-4">
          <footer>
            <button className="hxBtn" onClick={handleLogout}>
              Log Out
            </button>
          </footer>
        </section>
      </hx-menu>
    </div>
  );
};

HeaderDropdown.propTypes = {
  homeAccountId: PropTypes.string,
  username: PropTypes.string,
};

export default HeaderDropdown;
