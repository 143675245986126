export function trackCustomEvent({ action: eventAction, category: eventCategory, label: eventLabel }) {
  try {
    window.dataLayer.push({
      event: "ga.event",
      eventValue: "0",
      eventAction,
      eventCategory,
      eventLabel
    })
  }
  catch(error) {
    console.error(`dataLayer object not found ${error}`)
  }
}