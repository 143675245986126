const any200LevelStatus = '2XX';
const defaultMethod = 'GET';
const defaultExpectedStatues = [any200LevelStatus];
const noop = () => ({});
const isBrowser = typeof window !== `undefined`;

class RequestHelper {
  constructor(windowFetch = isBrowser ? window.fetch.bind(window) : noop) {
    this._windowFetch = windowFetch;
  }

  request = async (uri, request = {}) => {
    let reqInfo = {
      credentials: 'same-origin',
      headers: new Headers(),
      method: defaultMethod,
      requireResponseBody: true,
      expectedStatuses: defaultExpectedStatues,
      ...request,
    };

    if (reqInfo.body && reqInfo.body instanceof Object) {
      reqInfo.headers.set('Content-Type', 'application/json');
      reqInfo.body = JSON.stringify(reqInfo.body);
    }

    let result = {
      data: null,
      fetchError: null,
      headers: new Headers(),
      parseError: null,
      status: 0,
      statusText: '',
      success: false,
    };

    let response;

    try {
      response = await this._windowFetch(uri, reqInfo);

      let { headers, status, statusText } = response;
      result.headers = headers;
      result.success = this._getIsStatusExpected(
        status,
        reqInfo.expectedStatuses
      );
      result.status = status;
      result.statusText = statusText;
    } catch (ex) {
      result.fetchError = ex;
    }

    if (!result.fetchError) {
      if (response.status !== 0 && response.status < 500) {
        try {
          result.data = await response.json();
        } catch (ex) {
          if (reqInfo.requireResponseBody && response.status !== 204) {
            result.success = false;
          }

          result.parseError = ex;
        }
      }
    }

    return result;
  };

  _getIsStatusExpected(actualStatus, expectedStatuses) {
    actualStatus = actualStatus.toString();
    expectedStatuses = expectedStatuses.map((x) => x.toString().toUpperCase());

    if (expectedStatuses.includes(actualStatus)) {
      return true;
    }

    const actualStatusWas200Level = actualStatus.charAt(0) === '2';
    if (
      expectedStatuses.includes(any200LevelStatus) &&
      actualStatusWas200Level
    ) {
      return true;
    }

    return false;
  }
}

let { request } = new RequestHelper();

export {
  defaultMethod,
  defaultExpectedStatues,
  request as default,
  RequestHelper,
};
