import React from 'react';

const Footer = () => {
  return (
    <footer id="foot">
      &copy; {new Date().getFullYear()} Rackspace US, Inc.
      <nav>
        <a href="https://www.rackspace.com/information/legal/websiteterms">
          Website Terms
        </a>
        <a href="https://www.rackspace.com/information/legal/privacystatement">
          Privacy Policy
        </a>
        <a href="https://www.rackspace.com/information/legal/office-365">
          Office 365 Terms
        </a>
        <hx-icon
          class="align-top ml-1 text-cyan-500"
          id="tool-tip"
          type="exclamation-circle"
        ></hx-icon>
        <hx-tooltip for="tool-tip" position="top-right">
          By accessing, browsing or using this application, you will be
          accepting the most recent version of the Rackspace Technology Terms
          and Conditions.
        </hx-tooltip>
      </nav>
    </footer>
  );
};

export default Footer;
