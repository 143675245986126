import * as msal from '@azure/msal-browser';

const isProd = process.env.NODE_ENV === 'production';
const redirectUri = isProd ? 'https://support.manage365.rackspace.com/?login' : 'http://localhost:8000/?login';

const msalConfig = {
  auth: {
    clientId: 'b1902229-67ff-4a90-9a15-f90d71273438',
    navigateToLoginRequestUrl: false,
    redirectUri,
  },
};

const msalInstance =
  typeof msal.PublicClientApplication !== 'undefined'
    ? new msal.PublicClientApplication(msalConfig)
    : {};

// msalInstance.getAllAccounts() will return a list of logged in accounts
// possible to be logged in with multiple at the same time, so we may want
// or need to manage a user context

export default msalInstance;