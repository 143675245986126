import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './sidebar.module.scss';
import loaderStyles from '../shared/loadable.module.scss';
import { getProductName } from '../utils/productMapper.js';

const UNKNOWN_PRODUCT = 'Unknown';

const Sidebar = ({ isLoading, isShown, assignedLicenses, name }) => {
  const animation = classnames({
    [styles.openSidebar]: isShown,
  });
  const licenses = assignedLicenses?.map((l) => getProductName(l?.skuId));
  const hasLicenses =
    licenses?.length > 0 && licenses?.some((l) => l !== UNKNOWN_PRODUCT);

  return (
    <React.Fragment>
      <a className={classnames('text-cyan-900', styles.triggerMenu)} href="#0">
        <hx-icon type="list"></hx-icon>
      </a>
      <div className={classnames('bg-gray-100', styles.sidebar, animation)}>
        {isLoading ? (
          <React.Fragment>
            <div
              className={loaderStyles.loadable}
              style={{ height: '80px' }}
            ></div>
            <div
              className={loaderStyles.loadable}
              style={{ height: '10px' }}
            ></div>
            <div
              className={loaderStyles.loadable}
              style={{ height: '10px' }}
            ></div>
          </React.Fragment>
        ) : (
          <div className={styles.content}>
            <div className="pb-5">
              <h3>Welcome,</h3>
              <h3 className="break-words">{name}</h3>
            </div>
            <div className="pb-5">
              <p className="text-green-900">Success!</p>
              <p>The login credentials are valid.</p>
            </div>
            {hasLicenses && <p className="font-bold">Your Licenses:</p>}
            {hasLicenses &&
              licenses.map((l) =>
                l !== UNKNOWN_PRODUCT ? <p key={l}>{l}</p> : null
              )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isShown: PropTypes.bool.isRequired,
  assignedLicenses: PropTypes.array,
  name: PropTypes.string,
};

export default Sidebar;
