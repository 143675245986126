import {
  AUTHENTICATE_USER,
  READ_USER_PROFILE,
  LOAD_ASSIGNED_LICENSES,
  LOG_OUT,
  LOG_IN,
} from './actionTypes';
import { COMPLETED, ERROR, PENDING } from '../utils/statusTypes';
import msalInstance from '../auth';
import request from '../utils/apiClientRequest.js';

const graphAPI = 'https://graph.microsoft.com/v1.0/me';

export const authenticateUser = () => async (dispatch) => {
  dispatch({ type: AUTHENTICATE_USER, status: PENDING });

  try {
    const response = await msalInstance.handleRedirectPromise();

    if (response === null) {
      dispatch({ type: AUTHENTICATE_USER, status: COMPLETED });
      return;
    }

    if (!!response) {
      const { account, accessToken } = response;

      dispatch({
        type: AUTHENTICATE_USER,
        status: COMPLETED,
        account,
        loginStatus: COMPLETED,
      });

      return accessToken;
    }
  } catch (err) {
    dispatch({
      type: AUTHENTICATE_USER,
      status: ERROR,
    });
  }
};

export const readUserProfile = ({ accessToken }) => async (dispatch) => {
  dispatch({ type: READ_USER_PROFILE, status: PENDING });

  const response = await request(graphAPI, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const { data, success } = response;

  if (!success) {
    dispatch({ type: READ_USER_PROFILE, status: ERROR });
    return;
  }

  const { displayName } = data;

  dispatch({
    type: READ_USER_PROFILE,
    status: COMPLETED,
    profile: { displayName },
  });
};

export const logInUser = () => async (dispatch) => {
  dispatch({
    type: LOG_IN,
    status: PENDING,
  });

  try {
    msalInstance.loginRedirect({
      prompt: 'select_account',
      domainHint: 'organizations',
      scopes: ['user.read'],
    });
  } catch (err) {
    dispatch({
      type: LOG_IN,
      status: ERROR,
    });
  }
};

export const logOutUser = ({ homeAccountId }) => (dispatch) => {
  dispatch({ type: LOG_OUT, status: PENDING });

  const currentAccount = msalInstance.getAccountByHomeId(homeAccountId);

  msalInstance.logout(currentAccount);
};

export const loadAssignedLicenses = ({ accessToken }) => async (dispatch) => {
  dispatch({ type: LOAD_ASSIGNED_LICENSES, status: PENDING });

  const response = await request(graphAPI + '?$select=assignedLicenses', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const { data, success } = response;

  if (!success) {
    dispatch({ type: LOAD_ASSIGNED_LICENSES, status: ERROR });
    return;
  }

  dispatch({
    type: LOAD_ASSIGNED_LICENSES,
    status: COMPLETED,
    assignedLicenses: data.assignedLicenses,
  });
};
